import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { fetchWrap as fetch } from '../common/functions';
import { showMessage } from '../common';

const AddCustomerDialog = ({open, service, onClose, onSave}) => {

	const [name, setName] = useState('');
	const [testing, setTesting] = useState(false);

	const addCustomer = () => {
		fetch(`${process.env.REACT_APP_API}/services/${service.id}/customers`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name,
				testing
			})
		})
		.then((response) => {
			showMessage('Customer added');
			onSave();
		})
		.catch((error) => {
			showMessage('Failed to add customer', true);
			console.error(error);
		});
	};

	return (
		<Dialog open={open} fullWidth>
			<DialogTitle>
				Add customer
			</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					required
					type="text"
					id="newStoreDialogName"
					name="newStoreDialogName"
					label="Name"
					fullWidth
					variant="filled"
					value={name}
					onChange={(event) => setName(event.target.value)}
				/>
				<FormControlLabel
			        control={
			          <Checkbox
			            checked={testing}
			            onChange={(event) => setTesting(event.target.checked)}
			          />
			        }
			        label='R&amp;D'
			      />
			</DialogContent>
			<DialogActions>
                <Button color="primary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={addCustomer}>
                    Add
                </Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddCustomerDialog;
