import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchWrap as fetch } from '../common/functions';
import { showMessage } from '../common';

const SelectUserDialog = ({users, open, context, filter, onFilterChange, onAddNew, onClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const attachContext = (context, user) => {
        fetch(`${process.env.REACT_APP_API}/contexts/${context}/${user}`, {method: 'POST'})
        .then((response) => {
            showMessage('User added');
            onClose();
        })
        .catch((error) => {
			showMessage('Failed to add user', true);
			console.error(error);
		});
    };

    return (
        <Dialog open={open} fullWidth fullScreen={fullScreen}>
            <DialogTitle>
                Add user
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label='Name'
                    fullWidth
                    variant='filled'
                    value={filter}
                    onChange={onFilterChange}
                />
                <List style={{height: !fullScreen && 400, overflow: 'auto'}}>
                    {users
                        .filter((user) => user.name.toLowerCase().includes(filter.toLowerCase()) || user.email.toLowerCase().includes(filter.toLowerCase()))
                        .filter((user) => !user.behaviors.privileged.contexts.some((c) => c.context === context))
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                        .map((user) => (
                            <ListItem
                                button key={user.id}
                                value={user.id}
                                onClick={() => attachContext(context, user.id)}
                            >
                                <ListItemText disableTypography>
                                    <Typography variant="body2">
                                        {user.name} <br></br> {user.email}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        ))}
                    <ListItem>
                        <ListItemText>
                            <Button color="primary" onClick={onAddNew}>
                                Add new user
                            </Button>
                        </ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectUserDialog;
