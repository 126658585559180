import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grow from '@material-ui/core/Grow';

let showMessageFn;

const Notifier = ({ theme, anchorOrigin, transitionComponent }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);
    showMessageFn = (message, error = false) => {
        setMessage(message);
        setOpen(true);
        setError(error);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
            TransitionComponent={transitionComponent}
        >
            <SnackbarContent
                style={error ? {backgroundColor: theme.palette.error.dark} : {backgroundColor: green[600]}}
                message={message}
            />
        </Snackbar>
    );
};

export const showMessage = (value, error = false) => {
    showMessageFn(value, error);
};

Notifier.propTypes = {
    anchorOrigin: PropTypes.object.isRequired,
    transitionComponent: PropTypes.elementType.isRequired
};

Notifier.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transitionComponent: Grow
};

export default withTheme(Notifier);
