import { useEffect } from 'react';

const Callback = ({ history, children, userManager }) => {
    useEffect(() => {
        userManager.signinRedirectCallback()
            .then((user) => {
				fetch(`${process.env.REACT_APP_API}/login`, {
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${user.id_token}`
					}
				})
				.then((response) => {
					history.push('/');
				});
            })
            .catch((error) => {
                console.error(error);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children || null;
};

export default Callback;
