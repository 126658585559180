import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { stableSort } from './functions';

const styles = (theme) => ({});

const HiddenWrapper = ({breakpoint, ...props}) => (
    <Hidden
        xsDown={breakpoint === 'xs'}
        smDown={breakpoint === 'sm'}
        mdDown={breakpoint === 'md'}
        lgDown={breakpoint === 'lg'}
        xlDown={breakpoint === 'xl'}
        {...props}
    />
);

const Table = ({data, idKey, columns, rowActions, selection, onRowClick, onSelectedChanged, defaultOrderBy, defaultOrder}) => {
    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderby] = useState(defaultOrderBy);
    useEffect(() => {
        if (onSelectedChanged) {
            onSelectedChanged(selected);
        }
    }, [selected, onSelectedChanged]);

    const onRequestSort = (event, key) => {
        setOrder(orderBy === key && order === 'desc' ? 'asc' : 'desc');
        setOrderby(key);
    };

    const onSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(data.map((item) => item[idKey]));
        } else {
            setSelected([]);
        }
    };

    const onSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [...selected];

        if (selectedIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const rows = stableSort(data, orderBy, order);
    return (
        <Paper style={{overflowX: 'auto'}}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {selection && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={selected.length > 0}
                                    onChange={onSelectAllClick}
                                />
                            </TableCell>
                        )}
                        {columns.map((column) => {
                            return (
                                <HiddenWrapper key={column.key} breakpoint={column.breakpoint}>
                                    <TableCell component='th' scope='row' style={{width: column.width}}>
                                        <TableSortLabel
                                            active={orderBy === column.key}
                                            direction={order}
                                            onClick={(event) => { onRequestSort(event, column.key); }}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                </HiddenWrapper>
                            );
                        })}
                        {rowActions && <TableCell component='th' scope='row' />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((item) => {
                        return (
                            <TableRow
                                key={item[idKey]}
                                hover={onRowClick != null}
                                onClick={onRowClick ? () => onRowClick(item) : null}
                                selected={selected.includes(item[idKey])}
                            >
                                {selection && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selected.includes(item[idKey])}
                                            onChange={(event) => onSelect(event, item[idKey])}
                                        />
                                    </TableCell>
                                )}
                                {columns.map((column) => {
                                    return (
                                        <HiddenWrapper key={column.key} breakpoint={column.breakpoint}>
                                            <TableCell style={{width: column.width}}>
                                                {column.callback ? column.callback(item[column.key]) : item[column.key]}
                                            </TableCell>
                                        </HiddenWrapper>
                                    );
                                })}
                                {rowActions &&
                                    <TableCell align='right' style={{whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0}}>
                                        {rowActions(item)}
                                    </TableCell>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </MuiTable>
        </Paper>
    );
};

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    idKey: PropTypes.string.isRequired,
    selection: PropTypes.bool,
    onSelectedChanged: PropTypes.func,
    rowActions: PropTypes.func,
    defaultOrderBy: PropTypes.string,
    defaultOrder: PropTypes.string,
    onRowClick: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string.isRequired,
        callback: PropTypes.func,
        width: PropTypes.number,
        breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
    })).isRequired
};

Table.defaultProps = {
    idKey: 'id',
    defaultOrder: 'asc',
    defaultOrderBy: ''
};

export default withStyles(styles)(Table);
