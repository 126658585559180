import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form } from '../common';

const AddFirmwareVersionDialog = ({ open, title, maintenanceLevels, flavors, formId, onClose, onSave }) => {
    const [udi, setUdi] = useState('');
    const [version, setVersion] = useState('');
    const [flavor, setFlavor] = useState('');
    const [maintenanceLevel, setMaintenanceLevel] = useState('');
    useEffect(() => {
        if (open) {
            setUdi('');
            setVersion('');
            setFlavor('');
            setMaintenanceLevel('');
        }
    }, [open, setUdi]);
    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form action={`${process.env.REACT_APP_API}/versions/firmwares`} method='POST' onResponse={onSave} id={formId}>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='udi'
                        name='udi'
                        label='UDI'
                        fullWidth
                        value={udi}
                        onChange={(event) => setUdi(event.target.value)}
                    />
                    <TextField
                        required
                        margin='dense'
                        id='version'
                        name='version'
                        label='Version'
                        fullWidth
                        value={version}
                        onChange={(event) => setVersion(event.target.value)}
                    />
                    <TextField
                        required
                        select
                        margin='dense'
                        id='flavor'
                        name='flavor'
                        label='Flavor'
                        fullWidth
                        value={flavor}
                        onChange={(event) => setFlavor(event.target.value)}
                    >
                        {flavors.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        select
                        margin='dense'
                        id='maintenanceLevel'
                        name='maintenanceLevel'
                        label='Mainenance Level'
                        fullWidth
                        value={maintenanceLevel}
                        onChange={(event) => setMaintenanceLevel(event.target.value)}
                    >
                        {maintenanceLevels.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </TextField>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Cancel
                </Button>
                <Button color='primary' type='submit' form={formId}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddFirmwareVersionDialog.propTypes = {
    flavors: PropTypes.array.isRequired,
    maintenanceLevels: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddFirmwareVersionDialog;
