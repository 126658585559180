import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { LoadingBar, VirtualizedTable, ConfirmDialog, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { includesValue, hasPermission, fetchWrap as fetch } from '../common/functions';

const Devices = ({ customer, permissions }) => {
    const [search, setSearch] = useState('');
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [item, setItem] = useState(null);
    const [{data, loading, refresh}] = useFetch(`${process.env.REACT_APP_API}/customers/${customer.id}/devices`);
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        Devices
                    </Typography>
                    <TextField
                        label='Search'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={data ? data.filter((item) => includesValue(item, search)) : []}
                    columns={[
                        {label: 'Device', key: 'deviceRef'},
                        {label: 'Created', key: 'created', callback: (value) => moment(value).format('DD.MM.YYYY HH:mm')},
                        {label: 'Software version', key: 'swVersion'},
                    ]}
                    height={'calc(100vh - 200px)'}
                    rowActions={(item) => (
                        <>
                            {hasPermission(permissions, 'devices.delete') && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setItem(item);
                                        setRemoveDialogOpen(true);
                                    }}
                                >
                                    Remove
                                </Button>
                            )}
                        </>
                    )}
                />
            </Container>
            <ConfirmDialog
                open={removeDialogOpen}
                title='Remove Device'
                text='Are you sure you want to remove device?'
                confirmText='Remove'
                onClose={() => setRemoveDialogOpen(false)}
                onConfirm={() => {
                    setRemoveDialogOpen(false);
                    fetch(`${process.env.REACT_APP_API}/customers/${customer.id}/devices/${item.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            showMessage('Device removed');
                            refresh();
                        })
                        .catch((error) => {
                            showMessage('Failed to remove device', true);
                        });
                }}
            />
        </>
    );
};

export default Devices;
