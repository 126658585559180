import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { LoadingBar, VirtualizedTable, ConfirmDialog, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { includesValue, hasPermission, fetchWrap as fetch } from '../common/functions';
import AddCodeDialog from './AddCodeDialog';

let interval;

const formatCode = (code) => {
    const chars = code.split('');
    for (let i = 4; i < chars.length; i+=4) {
        chars.splice(i, 0, ' ');
        i++;
    }
    return chars.join('');
};

const Codes = ({ customer, permissions }) => {
    const [search, setSearch] = useState('');
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [item, setItem] = useState(null);
    const [{data, loading, refresh}] = useFetch(`${process.env.REACT_APP_API}/customers/${customer.id}/deviceactivation`);
    useEffect(() => {
        interval = setInterval(() => refresh(), 60000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        Unused activation codes
                    </Typography>
                    <TextField
                        label='Search'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                        style={{marginRight: '2em'}}
                    />
                    {hasPermission(permissions, 'codes.create') && (
                        <Button variant='contained' color='primary' onClick={() => setAddDialogOpen(true)}>Add Activation Code</Button>
                    )}
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={data ? data.filter((item) => includesValue(item, search)) : []}
                    columns={[
                        {label: 'Device', key: 'deviceRef'},
                        {label: 'Code', key: 'activationCode', callback: (value) => formatCode(value)},
                        {label: 'Created', key: 'created', callback: (value) => moment(value).format('DD.MM.YYYY HH:mm')},
                        {label: 'Valid until', key: 'validUntil', callback: (value) => moment(value).format('DD.MM.YYYY HH:mm')}
                    ]}
                    height={'calc(100vh - 200px)'}
                    rowActions={(item) => (
                        <>
                            {hasPermission(permissions, 'codes.delete') && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setItem(item);
                                        setRemoveDialogOpen(true);
                                    }}
                                >
                                    Remove
                                </Button>
                            )}
                        </>
                    )}
                />
            </Container>
            <ConfirmDialog
                open={removeDialogOpen}
                title='Remove activation code'
                text='Are you sure you want to remove activation code?'
                confirmText='Remove'
                onClose={() => setRemoveDialogOpen(false)}
                onConfirm={() => {
                    setRemoveDialogOpen(false);
                    fetch(`${process.env.REACT_APP_API}/customers/${customer.id}/deviceactivation/${item.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            showMessage('Activation code removed');
                            refresh();
                        })
                        .catch((error) => {
                            showMessage('Failed to remove activation code', true);
                        });
                }}
            />
            <AddCodeDialog
                open={addDialogOpen}
                customer={customer}
                title='Add activation code'
                formId='addCode'
                onClose={() => setAddDialogOpen(false)}
                onSave={() => {
                    setAddDialogOpen(false);
                    refresh();
                }}
            />
        </>
    );
};

export default Codes;
