const includesValue = (object, value) => {
    for (const key in object) {
        if (object[key] != null) {
            if (typeof object[key] === 'object') {
                const result = includesValue(object[key], value);
                if (result) {
                    return true;
                }
            } else {
                if (object[key].toString().includes(value)) {
                    return true;
                }
            }
        }
    }
    return false;
};

export default includesValue;
