import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingBar = ({in: loading}) => (
    <Fade in={loading}>
        <LinearProgress />
    </Fade>
);

PropTypes.LoadingBar = {
    in: PropTypes.bool
};

export default LoadingBar;
