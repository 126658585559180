import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { LoadingBar, ConfirmDialog, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch} from '../common/functions';

import AddUserDialog from './AddUserDialog';
import SelectUserDialog from './SelectUserDialog';
import AddCustomerDialog from './AddCustomerDialog';
import UserTable from './UserTable';

const AccessManagement = ({ service }) => {
	const [{data: customersData, loading: customersLoading, refresh: refreshCustomers}] = useFetch(`${process.env.REACT_APP_API}/services/${service.id}/customers`);
	const customers = customersData ? customersData.filter((item) => !item.deleted) : [];
	const [{data: userData, loading: usersLoading, refresh: refreshUsers}] = useFetch(`${process.env.REACT_APP_API}/users`);
	const users = userData ? userData.items : [];
	const roles = userData ? userData.roles : [];
	const [selectedContext, setSelectedContext] = useState(null);
	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
	const [selectUserDialog, setSelectUserDialog] = useState(false);
	const [selectUserDialogFilter, setSelectUserDialogFilter] = useState('');
	const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);
	const [removeCustomerDialogOpen, setRemoveCustomerDialogOpen] = useState(false);

	const disabledRoles = roles.filter((role) => role.privileges.includes('grant.master')).map((role) => role.id);
	const serviceContext = {value: service.id, label: service.name};
	customers.sort((a, b) => a.name > b.name ? 1 : -1);

	return (
		<>
			<LoadingBar in={customersLoading || usersLoading} />
			<Container maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Toolbar disableGutters={true}>
							<Typography variant="h3" style={{flexGrow: 1, paddingBottom: '0.25em'}}>
								{serviceContext.label}
							</Typography>
							<Button
								variant="contained"
								color="primary"
								style={{marginRight: '1em'}}
								onClick={() => setAddCustomerDialogOpen(true)}
							>
								Add customer
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setSelectedContext(serviceContext.value);
									setSelectUserDialog(true);
								}}
							>
								Add user
							</Button>
						</Toolbar>
						{users.length !== 0 && (
							<UserTable
								context={serviceContext.value}
								users={users.filter((user) => user.behaviors.privileged.contexts.some((c) => c.context === serviceContext.value))}
								roles={roles}
								disabledRoles={disabledRoles}
								onRoleChanged={refreshUsers}
							/>
						)}
					</Grid>

					<Grid item xs={12}>
						<Toolbar disableGutters>
							<Typography variant="h3">
								Customers
							</Typography>
						</Toolbar>
					</Grid>

					{customers.map((customer) => (
						<Grid item xs={12} key={customer.id}>
							<Toolbar disableGutters={true}>
								<Typography variant="h5" style={{flexGrow: 1}}>
									{customer.name} {customer.testing && '(R&D)'}
								</Typography>
								<Button
									variant="contained"
									color="primary"
									style={{marginRight: '1em'}}
									onClick={() => {
										setSelectedContext(customer.id);
										setSelectUserDialog(true);
									}}
								>
									Add user
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										setSelectedContext(customer.id);
										setRemoveCustomerDialogOpen(true);
									}}
								>
									Remove
								</Button>
							</Toolbar>
							{users.length !== 0 && (
								<UserTable
									context={customer.id}
									users={users.filter((user) => user.behaviors.privileged.contexts.some((c) => c.context === customer.id))}
									roles={roles}
									disabledRoles={disabledRoles}
									onRoleChanged={refreshUsers}
								/>
							)}
						</Grid>
					))}
				</Grid>
				<SelectUserDialog
					open={selectUserDialog}
					users={users}
					context={selectedContext}
					filter={selectUserDialogFilter}
					onFilterChange={(event) => setSelectUserDialogFilter(event.target.value)}
					onAddNew={() => {
						setSelectUserDialog(false);
						setAddUserDialogOpen(true);
					}}
					onClose={() => {
						refreshUsers();
						setSelectUserDialog(false);
					}}
				/>
				<AddUserDialog
					open={addUserDialogOpen}
					context={selectedContext}
					prefilledName={selectUserDialogFilter}
					onClose={() => {
						setSelectedContext(null);
						setAddUserDialogOpen(false);
					}}
					onSave={() => {
						setAddUserDialogOpen(false);
						refreshUsers();
					}}
				/>
				<AddCustomerDialog
					open={addCustomerDialogOpen}
					service={service}
					context={selectedContext}
					onClose={() => setAddCustomerDialogOpen(false)}
					onSave={() => {
						setAddCustomerDialogOpen(false);
						refreshCustomers();
					}}
				/>
				<ConfirmDialog
	                open={removeCustomerDialogOpen}
	                title='Remove customer'
	                text='Are you sure you want to remove customer?'
	                confirmText='Remove'
	                onClose={() => setRemoveCustomerDialogOpen(false)}
	                onConfirm={() => {
	                    setRemoveCustomerDialogOpen(false);
	                    fetch(`${process.env.REACT_APP_API}/customers/${selectedContext}`, {
	                        method: 'DELETE'
	                    })
	                        .then((response) => {
	                            showMessage('Customer removed');
	                            refreshCustomers();
	                        })
	                        .catch((error) => {
	                            showMessage('Failed to remove customer', true);
								console.error(error);
	                        });
	                }}
	            />
			</Container>
		</>
	);
};

export default AccessManagement;
