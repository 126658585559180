import React, { useState, useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import { fetch as fetchWrap } from '@project/components';
import Logo from './logo.png';

import DevicesIcon from '@material-ui/icons/PhonelinkRing';
import ActionIcon from '@material-ui/icons/CallToAction';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@material-ui/icons/ExitToApp';

// Routes
import { AccessManagement, Mailing, Devices, Codes, Versions, MSUs } from '@project/components';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    },
    main: {
        position: 'relative',
        flex: 1,
        overflow: 'auto'
    },
    link: {
        textDecoration: 'none',
        color: 'black'
    },
    linkIcon: {
        marginRight: '0.5em',
        color: theme.palette.primary.main
    },
    logout: {
        color: theme.palette.primary.main
    }
});

const App = ({classes, history, location: { pathname }}) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [customer, setCustomer] = useState('');
    const [serviceCustomers, setServiceCustomers] = useState([]);
    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
		fetch(`${process.env.REACT_APP_API}/contexts`, {
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			}
		})
    		.then((response) => {
    			if (!response.ok) {
    				if (response.status === 401 || response.status === 403) {
                        history.push('/login');
                        throw new Error('Not logged in');
    				}
    				throw new Error(response.status);
    			}
    			return response;
    		})
            .then((response) => response.json())
    		.then((response) => {
                setItems(response.items);
                setUser(response.user);
                const service = response.items.find((item) => item.type === 'service');
                if (service) {
                    fetchWrap(`${process.env.REACT_APP_API}/services/${service.id}/customers`)
                        .then((response) => {
                            setServiceCustomers(response);
                            // Auto-select first customer
                            if (response.length > 0) {
                                setCustomer(response[0]);
                            }
                            setLoading(false);
                        });
                } else {
                    // Auto-select first customer
                    const customers = response.items.filter((item) => item.type === 'customer');
                    if (customers.length > 0) {
                        setCustomer(customers[0]);
                    }
                    setLoading(false);
                }
            })
            .catch(console.error);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || !user) {
        return null;
    }

    const service = items.find((item) => item.type === 'service');
    const customers = !service ? items.filter((item) => item.type === 'customer') : serviceCustomers;
    const permissions = ['grant.master']

    return (
        <div className={classes.root}>
            <AppBar position='static' color='inherit'>
                <Toolbar>
                    <img src={Logo} alt='Logo' style={{width: 30, height: 30, marginRight: 10}} />
                    <Typography variant='h6' color='inherit' style={{whiteSpace: 'nowrap'}}>
                        CutoSense WoundWatch
                    </Typography>
                    <span style={{flexGrow: 1}} />
                    <Hidden mdDown>
                        <Link to='/devices' className={classes.link}>
                            <Button color='inherit'>
                                <DevicesIcon className={classes.linkIcon} />Devices
                            </Button>
                        </Link>
                        <Link to='/msus' className={classes.link}>
                            <Button color='inherit'>
                                <DeveloperBoardIcon className={classes.linkIcon} />MSUs
                            </Button>
                        </Link>
                        <Link to='/codes' className={classes.link}>
                            <Button color='inherit'>
                                <ActionIcon className={classes.linkIcon} />Activation Codes
                            </Button>
                        </Link>
                        {service && (
                            <Link to='/versions' className={classes.link}>
                                <Button color='inherit'>
                                    <DeveloperBoardIcon className={classes.linkIcon} />Versions
                                </Button>
                            </Link>
                        )}
                        {service && (
                            <Link to='/accessmanagement' className={classes.link}>
                                <Button color='inherit'>
                                    <VpnKeyIcon className={classes.linkIcon} />Access Management
                                </Button>
                            </Link>
                        )}
                        <span style={{flexGrow: 1}} />
                    </Hidden>
                    <Hidden lgUp>
                        <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                            <MenuItem component={Link} to='/devices' onClick={() => setMenuAnchor(null)}><DevicesIcon className={classes.linkIcon} />Devices</MenuItem>
                            <MenuItem component={Link} to='/codes' onClick={() => setMenuAnchor(null)}><ActionIcon className={classes.linkIcon} />Activation Codes</MenuItem>
                            {service && (
                                <MenuItem component={Link} to='/versions' onClick={() => setMenuAnchor(null)}><DeveloperBoardIcon className={classes.linkIcon} />Versions</MenuItem>
                            )}
                            {service && (
                                <MenuItem component={Link} to='/accessmanagement' onClick={() => setMenuAnchor(null)}><VpnKeyIcon className={classes.linkIcon} />Access Management</MenuItem>
                            )}
                        </Menu>
                    </Hidden>
                    {service || customers.length > 1 ? (
                        <NativeSelect
                            value={customer.id}
                            onChange={(event) => setCustomer(customers.find(item => item.id === event.target.value))}
    						name='customer'
                            color='secondary'
                            disabled={pathname.startsWith('/accessmanagement') || pathname.startsWith('/versions')}
                            style={{minWidth: 200}}
    					>
                            {customers.length === 0 && <option></option>}
    						{customers.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
    					</NativeSelect>
                    ) : (
                        <>{customer.name}</>
                    )}
                    <Tooltip title='Logout'>
                        <IconButton color='inherit' onClick={() => {
                            fetch(`${process.env.REACT_APP_API}/logout`, {
                                method: 'POST',
                                credentials: 'include',
                            })
                            .then((response) => {
                                history.push('/login');
                            });
                        }}>
                            <LogoutIcon className={classes.logout} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <main className={classes.main}>
                <Switch>
                    {service && (
                        <Route path='/mailing' component={() => (
                            <Mailing service={service} permissions={permissions} />
                        )} />
                    )}
                    <Route path='/devices' component={() => (
                        <Devices customer={customer} permissions={permissions} />
                    )} />
                    <Route path='/msus' component={() => (
                        <MSUs customer={customer} permissions={permissions} />
                    )} />
                    <Route path='/codes' component={() => (
                        <Codes customer={customer} permissions={permissions} />
                    )} />
                    {service && (
                        <Route path='/versions' component={() => (
                            <Versions service={service} permissions={permissions} />
                        )} />
                    )}
                    {service && (
                        <Route path='/accessmanagement' component={() => (
                            <AccessManagement service={service} permissions={permissions} />
                        )} />
                    )}
                </Switch>
            </main>
        </div>
    );
};

export default withStyles(styles)(App);
