import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form } from '../common';

const AddCodeDialog = ({ open, title, customer, formId, onClose, onSave }) => {
    const [deviceRef, setDeviceRef] = useState('');
    useEffect(() => {
        if (open) {
            setDeviceRef('');
        }
    }, [open, setDeviceRef]);
    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form action={`${process.env.REACT_APP_API}/customers/${customer.id}/deviceactivation`} method='POST' onResponse={onSave} id={formId}>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='deviceRef'
                        name='deviceRef'
                        label='Device Reference'
                        fullWidth
                        value={deviceRef}
                        onChange={(event) => setDeviceRef(event.target.value)}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    Cancel
                </Button>
                <Button color='primary' type='submit' form={formId}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddCodeDialog.propTypes = {
    customer: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default AddCodeDialog;
