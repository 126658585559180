import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { stableSort } from './functions';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRow: {
        cursor: 'pointer',
        outline: 'none'
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    selected: {
        backgroundColor: theme.palette.grey[100],
    },
    tableCell: {
        flex: 1
    },
    selectionCell: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 0 4px !important'
    },
    noClick: {
        cursor: 'initial',
    }
});

const isHidden = (breakpoint, width) => {
    return breakpoint && isWidthDown(breakpoint, width);
};

const VirtualizedTable = ({classes, theme, data, columns, selection, onSelectedChanged, rowActions, onRowClick, idKey, width: breakpointWidth, height: tableHeight, rowHeight, headerHeight, defaultOrder, defaultOrderBy, ...tableProps}) => {

    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderby] = useState(defaultOrderBy);
    useEffect(() => {
        if (onSelectedChanged) {
            onSelectedChanged(selected);
        }
    }, [selected, onSelectedChanged]);

    const onRequestSort = (event, key) => {
        setOrder(orderBy === key && order === 'desc' ? 'asc' : 'desc');
        setOrderby(key);
    };

    const getRowClassName = ({ index }) => {
        return clsx(classes.tableRow, classes.flexContainer, { [classes.tableRowHover]: index !== -1 && onRowClick != null });
    };

    const cellRenderer = ({ cellData, rowData, columnIndex, visibleColumns, ...rest }) => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.tableCell, classes.flexContainer, { [classes.noClick]: onRowClick == null},
                    { [classes.selected]: selected.includes(rowData[idKey])})}
                variant='body'
                style={{ height: rowHeight }}
            >
                {visibleColumns[columnIndex].callback ? visibleColumns[columnIndex].callback(cellData, rowData[idKey]) : cellData}
            </TableCell>
        );
    };

    const actionCellRenderer = ({rowData}) => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.tableCell, classes.flexContainer, {[classes.noClick]: onRowClick == null}, { [classes.selected]: selected.includes(rowData[idKey]) })}
                variant='body'
                align='right'
                style={{whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0, height: rowHeight}}
            >
                {rowActions(rowData)}
            </TableCell>
        );
    };

    const selectionCellRenderer = ({rowData}) => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.selectionCell, { [classes.noClick]: onRowClick == null}, { [classes.selected]: selected.includes(rowData[idKey]) })}
                variant='body'
                padding='checkbox'
                style={{ height: rowHeight }}
            >
                <Checkbox
                    checked={selected.includes(rowData[idKey])}
                    onChange={(event) => onSelect(event, rowData[idKey])}
                />
            </TableCell>
        );
    };

    const onSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [...selected];

        if (selectedIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const headerRenderer = ({ label, visibleColumns, columnIndex }) => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                style={{ height: headerHeight }}
                variant='head'
            >
                <TableSortLabel
                    active={orderBy === visibleColumns[columnIndex].key}
                    direction={order}
                    onClick={(event) => onRequestSort(event, visibleColumns[columnIndex].key)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    const actionHeaderRenderer = () => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                style={{ height: headerHeight }}
                variant='head'
            / >
        );
    };

    const selectionHeaderRenderer = () => {
        return (
            <TableCell
                component='div'
                className={clsx(classes.selectionCell, classes.noClick)}
                padding='checkbox'
                style={{ height: headerHeight }}
                variant='head'
            >
                <Checkbox
                    indeterminate={selected.length > 0 && selected.length < data.length}
                    checked={selected.length > 0}
                    onChange={onSelectAllClick}
                />
            </TableCell>
        );
    };

    const onSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(data.map((item) => item[idKey]));
        } else {
            setSelected([]);
        }
    };

    const sortedData = stableSort(data, orderBy, order);
    const visibleColumns = columns.filter(({ breakpoint }) => !isHidden(breakpoint, breakpointWidth));
    return (
        <Paper style={{ height: tableHeight }}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        rowCount={data.length}
                        rowGetter={({ index }) => sortedData[index]}
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        headerHeight={headerHeight}
                        rowClassName={getRowClassName}
                        onRowClick={onRowClick ? (row) => onRowClick(row.rowData) : null}
                        {...tableProps}
                    >
                        {selection && (
                            <Column
                                headerRenderer={(headerProps) => selectionHeaderRenderer({...headerProps})}
                                cellRenderer={selectionCellRenderer}
                                dataKey={'__emptyDataKey'}
                                width={48}
                            />
                        )}
                        {visibleColumns.map(({ key, width: columnWidth, ...other }, index) => (
                            <Column
                                key={key}
                                headerRenderer={(headerProps) => headerRenderer({...headerProps, visibleColumns, columnIndex: index})}
                                className={classes.flexContainer}
                                cellRenderer={(cellProps) => cellRenderer({...cellProps, visibleColumns, columnIndex: index})}
                                dataKey={key}
                                width={columnWidth || 200}
                                flexGrow={columnWidth ? 0 : 1}
                                {...other}
                            />
                        ))}
                        {rowActions && (
                            <Column
                                headerRenderer={(headerProps) => actionHeaderRenderer({...headerProps})}
                                className={classes.flexContainer}
                                cellRenderer={actionCellRenderer}
                                dataKey={'__emptyDataKey'}
                                width={200}
                                flexGrow={1}
                            />
                        )}
                    </Table>
                )}
            </AutoSizer>
        </Paper>
    );
};

VirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  idKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  selection: PropTypes.bool,
  rowActions: PropTypes.func,
  defaultOrderBy: PropTypes.string,
  defaultOrder: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      callback: PropTypes.func,
      width: PropTypes.number,
      breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
    }),
  ).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRowClick: PropTypes.func,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
};

VirtualizedTable.defaultProps = {
    idKey: 'id',
    defaultOrder: 'asc',
    defaultOrderBy: '',
    height: 400,
    headerHeight: 48,
    rowHeight: 48
};

export default withWidth()(withStyles(styles)(VirtualizedTable));
