const config = {
    oidc: {
    	authority: `https://${process.env.REACT_APP_JWT_DOMAIN}/authorize`,
    	client_id: process.env.REACT_APP_CLIENT_ID,
    	redirect_uri: `${window.location.origin}/callback`,
    	post_logout_redirect_uri: `${window.location.origin}`,
    	response_type: 'token id_token',
    	scope: 'openid',
    	loadUserInfo: true
    }
};

export default config;
