import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { LoadingBar, VirtualizedTable, showMessage, ConfirmDialog } from '../common';
import { useFetch } from '../common/hooks';
import { includesValue, hasPermission, fetchWrap as fetch } from '../common/functions';

const MSUs = ({ customer, permissions }) => {
    const [search, setSearch] = useState('');
    const [{data, loading, refresh}] = useFetch(`${process.env.REACT_APP_API}/customers/${customer.id}/msus`);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [item, setItem] = useState(null);

    const updateLeaseEnd = (id, date) => {
        console.log(date);
        fetch(`${process.env.REACT_APP_API}/customers/${customer.id}/msus/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                leaseEnd: date ? date._d.toISOString().split('T')[0] + 'T23:59:59.000Z' : null
            })
        })
            .then((response) => {
                showMessage('Lease end updated');
                refresh();
            });
    };

    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        MSUs
                    </Typography>
                    <TextField
                        label='Search'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={data ? data.filter((item) => includesValue(item, search)) : []}
                    columns={[
                        {label: 'Serial Number', key: 'serialNo'},
                        {label: 'Lease Start', key: 'leaseStart', callback: (value) => moment(value).format('DD.MM.YYYY')},
                        {label: 'Lease End', key: 'leaseEnd', callback: (value, id) => (
                            <DatePicker
                                clearable
                                value={value}
                                onChange={(date) => updateLeaseEnd(id, date)}
                                format='DD.MM.YYYY'
                            />
                        )},
                        {label: 'Software Version', key: 'swVersion'},
                        {label: 'Hardware Version', key: 'hwVersion'},
                    ]}
                    height={'calc(100vh - 200px)'}
                    rowActions={(item) => (
                        <>
                            {hasPermission(permissions, 'devices.delete') && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setItem(item);
                                        setRemoveDialogOpen(true);
                                    }}
                                >
                                    Remove
                                </Button>
                            )}
                        </>
                    )}
                />
            </Container>
            <ConfirmDialog
                open={removeDialogOpen}
                title='Remove MSU'
                text='Are you sure you want to remove the MSU?'
                confirmText='Remove'
                onClose={() => setRemoveDialogOpen(false)}
                onConfirm={() => {
                    setRemoveDialogOpen(false);
                    fetch(`${process.env.REACT_APP_API}/customers/${customer.id}/msus/${item.id}`, {
                        method: 'DELETE'
                    })
                        .then((response) => {
                            showMessage('Device removed');
                            refresh();
                        })
                        .catch((error) => {
                            showMessage('Failed to remove device', true);
                        });
                }}
            />
        </>
    );
};

export default MSUs;
