import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { fetchWrap as fetch } from '../common/functions';
import { ConfirmDialog, showMessage } from '../common';

const ExternalStatusIcon = ({status}) => {
	if (status === 'synced') {
		return (
			<Tooltip title="User active">
				<CheckIcon />
			</Tooltip>
		);
	}
	if (status === 'failed') {
		return (
			<Tooltip title="Error">
				<ErrorIcon color="error" />
			</Tooltip>
		);
	}
	if (status === null) {
		return (
			<Tooltip title="Waiting activation">
				<AutorenewIcon />
			</Tooltip>
		);
	}
	return (
		<Tooltip title="Unknown state">
			<ErrorOutlineIcon />
		</Tooltip>
	);
};

const UserTable = ({context, users, roles, disabledRoles, onRoleChanged}) => {

	const [selectedUser, setSelectedUser] = useState(null);
	const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

	const grantRole = (context, user, role) => {
		fetch(`${process.env.REACT_APP_API}/roles/${role}/grant/${user}/${context}`, {method: 'POST'})
		.then((response) => onRoleChanged())
		.catch((error) => {
			showMessage('Failed to set role', true);
			console.error(error);
		});
	};

	const revokeRole = (context, user) => {
		fetch(`${process.env.REACT_APP_API}/roles/revoke/${user}/${context}`, {method: 'POST'})
		.then((response) => onRoleChanged())
		.catch((error) => {
			showMessage('Failed to revoke role', true);
			console.error(error);
		});
	};

	const onRoleChange = (context, user) => (event) => {
		const role = event.target.value;
		if (role) {
			grantRole(context, user, role);
		} else {
			revokeRole(context, user);
		}
	};

	const removeContext = (context, user) => {
		fetch(`${process.env.REACT_APP_API}/contexts/${context}/${user}`, {method: 'DELETE'})
		.then((response) => {
			showMessage('User removed');
			onRoleChanged();
		})
		.catch((error) => {
			showMessage('Failed to remove user', true);
			console.error(error);
		});
	};

	const roleForUser = (user) => {
		return user.behaviors.privileged.contexts.find((c) => c.context === context).role || '';
	};

	return (
		<>
			<Paper elevation={1}>
				<Table>
					<colgroup>
						<col style={{width: '30px'}} />
						<col style={{width: 'auto'}} />
						<col style={{width: 'auto'}} />
						<col style={{width: '170px'}} />
						<col style={{width: '300px'}} />
						<col style={{width: '50px'}} />
					</colgroup>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Nimi</TableCell>
							<TableCell>Sähköposti</TableCell>
							<TableCell>Puhelin</TableCell>
							<TableCell>Rooli</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user) => (
							<TableRow key={user.id}>
								<TableCell align="right">
									<ExternalStatusIcon status={user.behaviors.privileged.external_status} />
								</TableCell>
								<TableCell>{user.name}</TableCell>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.phone}</TableCell>
								<TableCell align="right" padding="checkbox">
									<TextField
										select
										fullWidth
										value={roleForUser(user)}
										onChange={onRoleChange(context, user.id)}
										disabled={disabledRoles.includes(roleForUser(user))}
										SelectProps={{native: true}}
									>
										<option></option>
										{roles.map((role) => (
											<option key={role.id} value={role.id} disabled={disabledRoles.includes(role.id)}>
												{role.name}
											</option>
										))}
									</TextField>
								</TableCell>
								<TableCell>
									<IconButton aria-label="Remove" size="small" onClick={() => {
										setDeleteUserDialogOpen(true);
										setSelectedUser(user);
									}}>
										<DeleteIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
			<ConfirmDialog
				open={deleteUserDialogOpen}
				title='Remove user'
				text={`Are you sure you want to remove user ${selectedUser ? selectedUser.name : ''}?`}
				confirmText='Remove'
				onClose={() => setDeleteUserDialogOpen(false)}
				onConfirm={() => {
					setDeleteUserDialogOpen(false);
					removeContext(context, selectedUser.id);
				}}
			/>
		</>
	);
};

export default UserTable;
