import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { LoadingBar, VirtualizedTable, showMessage } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import { useFetch } from '../common/hooks';
import AddApplicationVersionDialog from './AddApplicationVersionDialog';
import AddFirmwareVersionDialog from './AddFirmwareVersionDialog';
import AddHardwareVersionDialog from './AddHardwareVersionDialog';

const flavors = ['RND', 'Consumer'];
const maintenanceLevels = ['ML0', 'ML1', 'ML2', 'ML3', 'ML4'];

const Versions = () => {
    const [{data: applicationsData, loading: applicationsLoading, refresh: refreshApplications}] = useFetch(`${process.env.REACT_APP_API}/versions/applications`);
    const [{data: firmwaresData, loading: firmwaresLoading, refresh: refreshFirmwares}] = useFetch(`${process.env.REACT_APP_API}/versions/firmwares`);
    const [{data: hardwaresData, loading: hardwaresLoading, refresh: refreshHardwares}] = useFetch(`${process.env.REACT_APP_API}/versions/hardwares`);
    const [addApplicationVersionDialogOpen, setAddApplicationVersionDialogOpen] = useState(false);
    const [addFirmwareVersionDialogOpen, setAddFirmwareVersionDialogOpen] = useState(false);
    const [addHardwareVersionDialogOpen, setAddHardwareVersionDialogOpen] = useState(false);

    const updateAppMaintenanceLevel = (id) => (event) => {
        fetch(`${process.env.REACT_APP_API}/versions/applications/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                maintenanceLevel: event.target.value
            })
        })
            .then((response) => {
                showMessage('Maintenance level updated');
                refreshApplications();
            });
    };

    const updateAppNewsFlag = (id) => (event) => {
        console.log(event.target.checked);
        fetch(`${process.env.REACT_APP_API}/versions/applications/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                newsFlag: event.target.checked
            })
        })
            .then((response) => {
                showMessage('News flag updated');
                refreshApplications();
            });
    };

    const updateFirmwareMaintenanceLevel = (id) => (event) => {
        fetch(`${process.env.REACT_APP_API}/versions/firmwares/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                maintenanceLevel: event.target.value
            })
        })
            .then((response) => {
                showMessage('Maintenance level updated');
                refreshFirmwares();
            });
    };

    const updateHardwareMaintenanceLevel = (id) => (event) => {
        fetch(`${process.env.REACT_APP_API}/versions/hardwares/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                maintenanceLevel: event.target.value
            })
        })
            .then((response) => {
                showMessage('Maintenance level updated');
                refreshHardwares();
            });
    };

    return (
        <>
            <LoadingBar in={applicationsLoading || firmwaresLoading || hardwaresLoading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        Mobile app
                    </Typography>
                    <Button variant='contained' color='primary' onClick={() => setAddApplicationVersionDialogOpen(true)}>Add version</Button>
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={applicationsData || []}
                    defaultOrderBy='version'
                    defaultOrder='desc'
                    columns={[
                        {label: 'GS1 GTIN', key: 'udi'},
                        {label: 'Version', key: 'version', width: 200},
                        {label: 'Build-time', key: 'buildTime', callback: (value) => value ? moment(value).format('DD.MM.YYYY HH:mm') : ''},
                        {label: 'Build commit hash', key: 'buildCommitHash'},
                        {label: 'Flavor', key: 'flavor', width: 200},
                        {label: 'Maintenance level', key: 'maintenanceLevel', width: 200, callback: (value, id) => (
                            <TextField
                                select
                                value={value}
                                onChange={updateAppMaintenanceLevel(id)}
                                style={{width: 120}}
                            >
                                {maintenanceLevels.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        )},
                        {label: 'News', key: 'newsFlag', callback: (value, id) => (
                            <Checkbox
                                checked={value}
                                onChange={updateAppNewsFlag(id)}
                            />
                        )}
                    ]}
                    height={400}
                />
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        MSU Firmware
                    </Typography>
                    <Button variant='contained' color='primary' onClick={() => setAddFirmwareVersionDialogOpen(true)}>Add version</Button>
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={firmwaresData || []}
                    defaultOrderBy='version'
                    defaultOrder='desc'
                    columns={[
                        {label: 'UDI', key: 'udi'},
                        {label: 'Version', key: 'version', width: 200},
                        {label: 'Flavor', key: 'flavor', width: 200},
                        {label: 'Maintenance level', key: 'maintenanceLevel', width: 200, callback: (value, id) => (
                            <TextField
                                select
                                value={value}
                                onChange={updateFirmwareMaintenanceLevel(id)}
                                style={{width: 120}}
                            >
                                {maintenanceLevels.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        )}
                    ]}
                    height={400}
                />
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{flexGrow: 1}}
                    >
                        MSU Hardware
                    </Typography>
                    <Button variant='contained' color='primary' onClick={() => setAddHardwareVersionDialogOpen(true)}>Add version</Button>
                </Toolbar>
                <VirtualizedTable
                    idKey='id'
                    data={hardwaresData || []}
                    defaultOrderBy='version'
                    defaultOrder='desc'
                    columns={[
                        {label: 'UDI', key: 'udi'},
                        {label: 'Version', key: 'version', width: 200},
                        {label: 'Flavor', key: 'flavor', width: 200},
                        {label: 'Maintenance level', key: 'maintenanceLevel', width: 200, callback: (value, id) => (
                            <TextField
                                select
                                value={value}
                                onChange={updateHardwareMaintenanceLevel(id)}
                                style={{width: 120}}
                            >
                                {maintenanceLevels.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        )}
                    ]}
                    height={400}
                />
            </Container>
            <AddApplicationVersionDialog
                open={addApplicationVersionDialogOpen}
                title='Add mobile app version'
                formId='addApplication'
                flavors={flavors}
                maintenanceLevels={maintenanceLevels}
                onClose={() => setAddApplicationVersionDialogOpen(false)}
                onSave={() => {
                    showMessage('Mobile app version added');
                    setAddApplicationVersionDialogOpen(false);
                    refreshApplications();
                }}
            />
            <AddFirmwareVersionDialog
                open={addFirmwareVersionDialogOpen}
                title='Add firmware version'
                formId='addFirmware'
                flavors={flavors}
                maintenanceLevels={maintenanceLevels}
                onClose={() => setAddFirmwareVersionDialogOpen(false)}
                onSave={() => {
                    showMessage('Firmware version added');
                    setAddFirmwareVersionDialogOpen(false);
                    refreshFirmwares();
                }}
            />
            <AddHardwareVersionDialog
                open={addHardwareVersionDialogOpen}
                title='Add hardware version'
                formId='addHardware'
                flavors={flavors}
                maintenanceLevels={maintenanceLevels}
                onClose={() => setAddHardwareVersionDialogOpen(false)}
                onSave={() => {
                    showMessage('Hardware version added');
                    setAddHardwareVersionDialogOpen(false);
                    refreshHardwares();
                }}
            />
        </>
    );
};

export default Versions;
