import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { fetchWrap as fetch } from '../common/functions';
import { showMessage } from '../common';

const AddUserDialog = ({open, context, prefilledName, onClose, onSave}) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	useEffect(() => {
		if (open) {
			setName(prefilledName);
		}
	}, [open, prefilledName]);

	const attachContext = (user) => {
		fetch(`${process.env.REACT_APP_API}/contexts/${context}/${user}`, {method: 'POST'})
		.then((response) => {
			showMessage('User added');
			onSave();
		})
		.catch((error) => {
			showMessage('Failed to add user', true);
			console.error(error);
		});
	};

	const registerUser = () => {
		fetch(`${process.env.REACT_APP_API}/users/register`, {
			method: 'POST',
			body: JSON.stringify({
				user: {
					name,
					email,
					phone
				}
			})
		})
		.then((response) => {
			attachContext(response.item.id);
		})
		.catch((error) => {
			showMessage('Failed to add user', true);
			console.error(error);
		});
	};

	return (
		<Dialog open={open}>
			<DialogTitle>
				Add user
			</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					required
					type="text"
					id="newUserDialogName"
					name="newUserDialogName"
					label="Name"
					fullWidth
					variant="filled"
					value={name}
					onChange={(event) => setName(event.target.value)}
					margin='normal'
				/>
				<TextField
					required
					type="email"
					id="newUserDialogEmail"
					name="newUserDialogEmail"
					label="Email"
					fullWidth
					variant="filled"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					margin='normal'
				/>
				<TextField
					type="tel"
					id="newUserDialogPhone"
					name="newUserDialogPhone"
					label="Phone"
					fullWidth
					variant="filled"
					value={phone}
					onChange={(event) => setPhone(event.target.value)}
					margin='normal'
				/>
			</DialogContent>
			<DialogActions>
                <Button color="primary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={registerUser}>
                    Add
                </Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddUserDialog;
