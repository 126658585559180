import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

const MailPreview = ({open, onClose, onSave, onCheckRecipient, title, body, attachments, recipients, recipientSkip}) => {
	return (
		<Dialog open={open} fullWidth>
			<DialogContent>
				<Typography variant="h5">
					{title}
				</Typography>

				<Typography variant="body1" style={{whiteSpace: 'pre-wrap'}}>
					{body}
				</Typography>

				{attachments.length > 0 && (
					<>
						<Typography component="h5" variant="h5">
							Attachments
						</Typography>
						<Grid container spacing={2} style={{maxHeight: '300px', overflow: 'auto'}}>
							{attachments.map((attachment) => (
								<Grid item xs={12} key={attachment.id}>
									{attachment.name || attachment.title}
								</Grid>
							))}
						</Grid>
					</>
				)}

				<Typography variant="h5">
					Recipients
				</Typography>
				<Grid container spacing={2} style={{maxHeight: '300px', overflow: 'auto'}}>
					{recipients.map((recipient) => (
						<Grid item key={recipient.id}>
							<Chip clickable
								label={recipient.email}
								color={recipientSkip.find((user) => user === recipient.id) ? 'default' : 'secondary'}
								onClick={onCheckRecipient(recipient.id)}
								style={{margin: '0 0.5em'}}
							/>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions>
		        <Button color="primary" onClick={onClose}>
		            Cancel
		        </Button>
		        <Button color="primary" onClick={() => onSave()}>
		            Send
		        </Button>
			</DialogActions>
		</Dialog>
	);
};

export default MailPreview;
