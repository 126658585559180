import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        flexGrow: 1
    }
});

const Splitter = ({classes}) => <span className={classes.root} />;

export default withStyles(styles)(Splitter);
